<template>
  <div class="rct-page">
    <div class="engine">
      <van-row>
        <van-col span="12">
          <img
            class="img"
            :src="require('../../../../assets/images/people/match/engine-1.gif')"
          />
        </van-col>
        <van-col span="12">
          <div class="operate">
            <van-row class="item">
              <van-col span="10" class="title">岗位总数：</van-col>
              <van-col span="14" class="content"
                >{{
                  match.level.one +
                  match.level.two +
                  match.level.three +
                  match.level.four +
                  match.level.five
                }}个</van-col
              >
            </van-row>
            <van-row class="item">
              <van-col span="10" class="title">难度较小：</van-col>
              <van-col span="14" class="content"
                >{{ match.level.one + match.level.two }}个</van-col
              >
            </van-row>
            <van-row class="item">
              <van-col span="10" class="title">难度较大：</van-col>
              <van-col span="14" class="content"
                >{{
                  match.level.three + match.level.four + match.level.five
                }}个</van-col
              >
            </van-row>
            <van-row class="item">
              <van-col span="10" class="title">匹配时间：</van-col>
              <van-col span="14" class="content">{{ match.time }}</van-col>
            </van-row>
            <van-row>
              <van-col span="24"
                ><van-button
                  size="mini"
                  icon="play-circle-o"
                  :color="COLOR"
                  @click="startMatch()"
                  class="btn"
                  >重新匹配</van-button
                ></van-col
              >
            </van-row>
          </div>
        </van-col>
      </van-row>
    </div>
    <div class="record">
      <van-row class="title">
        <van-col span="12" class="text"
          ><van-icon name="notes-o" /> 职位列表</van-col
        >
      </van-row>
      <van-list
        :finished="finished"
        finished-text="没有更多了"
        offset="0"
        class="task-list"
        :immediate-check="false"
        @load="retrieveTaskList"
      >
        <template #default>
          <van-row
            class="task bdc-b1s-gray"
            v-for="task in taskList"
            :key="task.code"
            @click="detail(task.code)"
          >
            <van-col span="24" class="content">
              <van-row>
                <van-col span="17" class="name text-short">
                  <van-tag size="mini" :color="COLOR_M" v-if="task.score <= 20"
                    >简单</van-tag
                  >
                  <van-tag
                    size="mini"
                    :color="COLOR_M"
                    v-if="task.score > 20 && task.score <= 40"
                    >普通</van-tag
                  >
                  <van-tag
                    size="mini"
                    :color="COLOR_S1"
                    v-if="task.score > 40 && task.score <= 60"
                    >较难</van-tag
                  >
                  <van-tag
                    size="mini"
                    :color="COLOR_S2"
                    v-if="task.score > 60 && task.score <= 80"
                    >困难</van-tag
                  >
                  <van-tag
                    size="mini"
                    :color="COLOR_S2"
                    v-if="task.score > 80 && task.score < 100"
                    >极难</van-tag
                  >{{ task.name }}</van-col
                >
                <van-col
                  span="7"
                  class="salary fc-cyan"
                  v-if="task.salaryType === 'DAY'"
                  >{{ task.salaryMin.toFixed(1) }} -
                  {{ task.salaryMax.toFixed(1) }}元/天</van-col
                >
                <van-col
                  span="7"
                  class="salary fc-cyan"
                  v-if="task.salaryType === 'MOT'"
                  >{{ (task.salaryMin / 1000).toFixed(1) }} -
                  {{ (task.salaryMax / 1000).toFixed(1) }}K/月</van-col
                >
                <van-col
                  span="7"
                  class="salary fc-cyan"
                  v-if="task.salaryType === 'YER'"
                  >{{ (task.salaryMin / 10000).toFixed(1) }} -
                  {{ (task.salaryMax / 10000).toFixed(1) }}万/年</van-col
                >
              </van-row>
              <van-row>
                <van-col span="17" class="time"
                  >日期：{{ task.timeStart }}到{{ task.timeEnd }}</van-col
                >
                <van-col span="7" class="amount"
                  >人数:{{ task.amount }}人</van-col
                >
              </van-row>
            </van-col>
          </van-row>
        </template>
      </van-list>
      <van-row v-if="taskList.length <= 0">
        <van-col span="24" class="no-record">
          <van-image
            width="103"
            height="103"
            :src="require('../../../../assets/images/home/no-record.png')"
          ></van-image>
        </van-col>
      </van-row>
    </div>
    <Loading :show="loadingShow" />
  </div>
</template>
<script>
import { Image, List, Tag } from 'vant'
import Loading from '../../common/Loading.vue'
export default {
  components: {
    Loading: Loading,
    [Image.name]: Image,
    [List.name]: List,
    [Tag.name]: Tag
  },
  data () {
    return {
      loadingShow: false,
      finished: false,
      match: { level: { one: 0, two: 0, three: 0, four: 0, five: 0 }, time: '' },
      page: { current: 0, size: 10, last: 0 },
      taskList: []
    }
  },
  props: { person: { type: String } },
  mounted () {
    this.retrieveMatch()
  },
  methods: {
    async startMatch () {
      this.loadingShow = true
      var pd = { personCode: this.person, businessType: 'RCT' }
      const { data: res } = await this.$http.post(this.BMS_URL + '/people/match/startMatch', this.$qs.stringify(pd))
      this.loadingShow = false
      if (res.status === '200') {
        this.retrieveMatch()
      }
    },
    async retrieveMatch () {
      var pd = { personCode: this.person, businessType: 'RCT' }
      const { data: res } = await this.$http.post(this.BMS_URL + '/people/match/retrieveBusinessMatch', this.$qs.stringify(pd))
      if (res.status === '200') {
        this.match.level = res.data.level
        this.match.time = res.data.endTime
        this.page.current = 0
        this.retrieveTaskList()
      }
    },
    loadTaskList () {
      if (this.page.last >= this.page.current) {
        this.retrieveTaskList()
      } else {
        this.finishedShow = true
      }
    },
    async retrieveTaskList () {
      if (!this.finished) {
        this.loadingShow = true
        this.page.current = this.page.current + 1
        var pd = { personCode: this.person, current: this.page.current, size: this.page.size }
        var { data: res } = await this.$http.post(this.BMS_URL + '/recruit/taskAPC/retrieveMatchTaskList', this.$qs.stringify(pd))
        if (res.status === '200') {
          this.taskList = this.taskList.concat(res.data)
          this.page.last = res.page.last
          if (res.data.length <= 0) {
            this.finished = true
          }
        }
        this.loadingShow = false
      }
    },
    detail (code) {
      window.sessionStorage.setItem(this.SESSION_BACKURL, window.location.href)
      this.$router.push({ path: '/mde/recruit/detail', query: { taskCode: code, module: 'USR' } })
    }
  }
}
</script>
<style lang="less" scoped>
.rct-page {
  .engine {
    padding: 10px 0px;
    background-color: #000036;
    .img {
      width: 100%;
    }
    .operate {
      background-color: rgba(255, 255, 255, 0.3);
      height: 180px;
      width: 95%;
      margin-top: 10px;
      border-radius: 5px;
      padding: 10px 0px 0px 5px;
      .item {
        border-bottom: 1px dashed #eee;
        padding: 3px 0px;
        font-size: 14px;
        color: #fff;
        .title {
          text-align: right;
          font-weight: 800;
        }
        .content {
          text-align: left;
        }
      }
      .btn {
        margin-top: 10px;
        width: 60%;
      }
    }
  }
  .record {
    .title {
      border-bottom: 1px solid #eee;
      border-top: 1px solid #eee;
      .text {
        height: 30px;
        line-height: 30px;
        text-align: left;
        font-size: 14px;
        font-weight: 800;
        padding-left: 10px;
      }
      .btn {
        height: 30px;
        line-height: 30px;
        text-align: right;
        padding-right: 20px;
      }
    }
    .task-list {
      padding: 0px 0px;
      width: 98%;
      margin: auto;
      margin-bottom: 50px;
      .task {
        padding: 8px 0px;
        .name {
          font-size: 14px;
          font-weight: 700;
          text-align: left;
          padding: 1px 5px;
        }
        .salary {
          text-align: center;
          padding: 1px 5px;
          font-weight: 600;
          font-size: 13px;
        }
        .label {
          text-align: left;
          font-size: 12px;
          padding: 2px 3px;
          .item {
            padding: 2px 5px;
            margin-right: 3px;
          }
        }
        .expense {
          font-size: 13px;
          padding: 3px 0px;
          text-align: center;
        }
        .time {
          padding: 3px 5px;
          text-align: left;
          color: #888;
        }
        .region {
          font-size: 13px;
          padding: 3px 0px;
          text-align: center;
        }
        .amount {
          padding: 3px 5px;
          text-align: center;
          color: #888;
        }
      }
    }
  }
}
</style>
